import { inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree, UrlSerializer } from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "./auth.service";
import { StoreService } from "./store.service";
import { SocketService } from "./socket.service";
import { ApiService } from "./api.service";
import { InternalMessageService } from "./internalMessage.service";

@Injectable()
export class AuthGuard {
  authService: AuthService = inject(AuthService);
  router: Router = inject(Router);
  StoreService: StoreService = inject(StoreService);
  SocketService: SocketService = inject(SocketService);
  UrlSerializer: UrlSerializer = inject(UrlSerializer);
  ApiService: ApiService = inject(ApiService);
  InternalMessageService: InternalMessageService = inject(InternalMessageService)

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (state.url !== "/login" && state.url !== "/logout") {
      let url2 = state.url.replace(/\%3f/g, "?").replace(/\%3d/g, "=").replace(/\%3F/g, "?").replace(/\%26/g, "&").replace(/\%3D/g, "=");
      this.StoreService.addToStore("route", url2);
      if (this.StoreService.getFromStore_Static("token").email) {
        localStorage.setItem(this.StoreService.getFromStore_Static("token").email + "path", state.url);
      }
    }
    if (state.url == "/logout") {
      this.logout();
      return true;
    }
    if (state.url === "/login") {
      if (this.authService.token && localStorage.getItem(this.StoreService.getFromStore_Static("token").email + "path")) {
        //@ts-ignore
        let url2 = localStorage
          .getItem(this.StoreService.getFromStore_Static("token").email + "path")
          .replace(/\%3f/g, "?")
          .replace(/\%3d/g, "=")
          .replace(/\%3F/g, "?")
          .replace(/\%26/g, "&")
          .replace(/\%3D/g, "=");
        if (Object.keys(this.UrlSerializer.parse(url2).queryParams).length > 0) {
          this.router.navigate([`${url2.split("?")[0]}`], {
            queryParams: this.UrlSerializer.parse(url2).queryParams,
          });
        } else {
          this.router.navigate([`${url2}`]);
        }
      }
    }
    if (this.authService.isAuthenticated() && state.url !== "/login") {
      if (!this.SocketService.isConnected) {
        this.SocketService.connect();
      }
      this.InternalMessageService.clearMessageCollector();
      this.StoreService.addToStore_static("panel_name", route.data?.["panel_base"]);
      this.StoreService.addToStore_static("panel_base", route.data?.["panel_base"]);
    }
    return true;
  }
  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    if (state.url !== "/login" && state.url !== "/logout")
      if (this.StoreService.getFromStore_Static("token").email) {
        localStorage.setItem(this.StoreService.getFromStore_Static("token").email + "path", state.url);
      }
    this.setPanelBase(route, state);
    return true;
  }

  setPanelBase(route: any, state: any) {
    let url2 = state.url.replace(/\%3f/g, "?").replace(/\%3d/g, "=").replace(/\%3F/g, "?").replace(/\%3D/g, "=").replace(/\%26/g, "&");
    this.StoreService.addToStore("route", url2);
    if (this.authService.isAuthenticated() && state.url !== "/login") {
      if (route.params.api) {
        this.StoreService.addToStore_static("panel_name", route.params.api);
      } else if (route.data.api) {
        this.StoreService.addToStore_static("panel_name", route.data.api);
      }
    }
  }
  logout() {
    this.ApiService.Getmethod("account/logout").subscribe(
      (response: any) => {
        this.authService.logout();
        this.SocketService.remove_all_account_update();
        this.SocketService.disconnect();

        this.router.navigate([`login`]);
      },
      (err: any) => {
        this.authService.logout();
        this.SocketService.remove_all_account_update();
        this.SocketService.disconnect();
        this.router.navigate([`login`]);
      }
    );
  }
}
